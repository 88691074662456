import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Container } from "react-bootstrap"

import Layout from "../../components/layout"
import PostsList from "../../components/postlist"
import Slider from "../../components/slider"

const Hirek = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      q: allMarkdownRemark(
        limit: 1000
        filter: { fields: { category: { eq: "gyik" } } }
        sort: { fields: [frontmatter___title], order: ASC }
      ) {
        totalCount
        edges {
          node {
            fields {
              slug
              category
            }
            excerpt
            timeToRead
            frontmatter {
              title
              description
              date
            }
          }
        }
      }
    }
  `)

  const title = "GYIK"
  const subtitle = "Gyakran ismételt kérdések"

  return (
    <Layout location={location} title={title} description={subtitle}>

      <Slider title={title} subtitle={subtitle}/>

      <Container fluid>
        <PostsList postEdges={data.q.edges}/>
      </Container>
    </Layout>
  )
}

export default Hirek
